.experience div {
  margin-bottom: 0px;
  margin-top: 0;
}
.experience .experienceFlex {
  margin-bottom: 0px;
  margin-top: 0px;
  height: 300px;
  display: block;
}
.experienceDiv {
  display: inline-block;
  max-width: 500px;
  height: 250px;
  outline: auto;
  outline-color: #e4e4e4;
  background-color: #f3f3f3;
  border-radius: 1%;
  transition: all .6s ease;  
  margin-bottom: 10px;
  margin-top: 10px;

}
.experienceDiv:hover {
  outline-color: #c9c9c9;
  transition: outline-color .6s;
}
.experience .experienceDescription {
  display: inline-block;
  width: 95%;
  height: 85%;
  margin-top: 10px;
  text-align: left;
  vertical-align: top;
  padding-left: 2%;
  padding-right: 2%;
  outline: none;
}
.experienceDescription p{
  text-align: left;
}
.experienceDescription ul{
  margin: 0;
}
.experienceTop {
  display: flex;
  flex-direction: row;
  gap: 10%;
  align-items: center;
}
.experienceYear {
  width: 180px;
  text-align: right;
}
.experienceJob {
  width: 350px;
}
.experienceDiv h3 {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.experienceDiv h4 {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.experienceYear h4 {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  width: 160px;
}
.experienceFiller {
  display: inline-block;
  flex-grow: 1;
  min-height: 10px;
}
.experienceDiv li {
  margin-bottom: 10px;
}
@media (min-width: 100px) {
  .experienceYear h4 {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    width: 160px;
  }
  .experienceDiv {
    display: block;
    height: 340px;
    transition: all .5s;  
  }
  .experience .experienceFlex {
    
    margin-top: 80px;
  }
  .portfolioSections .experience {
    height: 850px;
    transition: all .5s;  
  }
}
@media (min-width: 370px) {
  .experienceYear h4 {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    width: 160px;
  }
  .experienceDiv {
    display: block;
    height: 300px;
    transition: all .5s;  
  }
  .experience .experienceFlex {
    
    margin-top: 30px;
  }
  .portfolioSections .experience {
    height: 790px;
    transition: all .5s;  
  }

}
@media (min-width: 530px) {
 .experienceDiv {
  display: inline-block;
  height: 250px;
  transition: all .5s;
}
.experience .experienceFlex {
    
  margin-top: 10px;
}
.portfolioSections .experience {
  height: 720px;
  transition: all .5s;  
}
}