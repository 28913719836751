@font-face {
  font-family: playFair;
  src: url(../fonts/PlayFairDisplay.ttf);
}

.portfolioSections {
  background-color: #faf7f7;
  padding: 2%;
}
.portfolioSections input {
  margin: 8px;
  border-color: #dfdfdf;
  border-radius: 5px;
  border-style: solid;
  max-width: 500px;
  padding-top: 5px;
}
.portfolioSections form {
  margin-top: 20px;
}
/* .portfolioSections  .projectDiv{
 outline: none;
} */

.contactEmail {
  height: 30px;
  padding-bottom: 5px;
}
.portfolioSections .contactComment {
  padding-bottom: 150px;
 }
.portfolioSections .contactButton {
  padding: 7px;
}
.portfolioSections .projects{
  margin-top: 3%;
}
.portfolioSections div{
  margin-bottom: 100px;
  text-align: center;
  margin-top: -60px; 
  scroll-margin-top: 100px;
}
.portfolioSections .skillsMain{
  margin-top: 35px;
}
.portfolioSections section{
  margin-bottom: 100px;
  text-align: center;
  margin-top: -60px; 
  z-index: -1;
  scroll-margin-top: 100px;
}
svg {
  width: 100px;
  transition: transform .2s ease-out;
}
svg:hover {
  transform: scale(1.15);
}
.skillsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
  list-style: none;
}
.portfolioSections .skills {
  min-height: 400px;
  margin-bottom: 20px;
}

.skillsFiller {
  display: inline-block;
  flex-grow: 1;
  min-height: 10px;
}
.skillsMain{
  display: inline-block;
  max-width: 600px;
}

@media (min-width: 100px) {
  svg {
    width: 60px;
    transition: transform .2s ease-out, width .5s;
  }
  .portfolioSections .projectDiv {
    outline: none;
  }
  .portfolioSections .experienceDiv {
    outline: none;
  }
}
@media (min-width: 1400px) {
  svg {
    width: 100px;
    transition: transform .2s ease-out, width .5s;
  }
  .portfolioSections .projectDiv {
    outline: auto;
    outline-color: #e4e4e4;
  }
  .portfolioSections .experienceDiv {
    outline: auto;
    outline-color: #e4e4e4;
  }
}