@font-face {
  font-family: playFair;
  src: url(fonts/PlayFairDisplay.ttf);
}

@keyframes example {
  0%   {background-color: #000000;}
  25%  {background-color: #020918;}
  50%  {background-color: #0b1a3b;}
  75% {background-color: #102450;}
  100%   {background-color: #000000;}
}

.bannerBackground {
  background-color: #102450;
  background-blend-mode: difference ;
  background-image: url('https://images.unsplash.com/photo-1554034483-04fda0d3507b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-repeat: round;
  transition: background-color 3s ease-in-out, height 1s ease-in-out;
  animation-name: example;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.banner{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  justify-content: end; 
  transition: all .6s ease;
}
.bannerExtended {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  justify-content: end; 
  transition: all .6s ease;
    
}
.bannerFlex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 60px;
  
}
.bannerPicture {
  height: 130px;
  width: 130px;
  background-color: #000000;
  border-radius: 50%;
  overflow: hidden;
}
.bannerPicture img{
  height: 150px;
  width: 150px;
  transform: translateX(-3%) translateY(-3%);
  border-radius: 50%;
}
/* .bannerPicture img{
  height: 320px;
  width: 320px;
  transform: translateX(-34%) translateY(-4%);
  border-radius: 50%;
} */
.aboutMe {
  margin-top: 20px;
  font-size: 1.4em;
  font-family: playFair;
}


.bannerExtension {
  display: flex;
  flex-direction: column;
  height: 30px;
  text-align: center;
  justify-content: end;
  align-items: center; 
  transition: all .6s ease; 
}
.bannerExtensionExtended {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;

  justify-content: end;
  align-items: center; 
  transition: all .6s ease;
}
.bannerButton {
  width: 200px;
  border-radius: 10px;
  opacity: .8;
}
@media (min-width: 100px) {
  .bannerFlex {
    margin-top: 100px;
  }
}
@media (min-width: 500px) {
  .bannerFlex {
    margin-top: 60px;
  }
}
