.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: roboto;
  src: url(fonts/RobotoSlab.ttf);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
}
p{
  margin: 0;
  padding: 0;
}
body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 1.8em;
}

h2 {
  margin-top: 0;
  font-size: 2em;
  font-family: roboto;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}
html { 
  scroll-behavior: smooth; 
}
.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 15px;
}

.game-info {
  margin-left: 20px;
}
.topBar{
  position: fixed;
  width: 100%;
  display: flex;
  list-style-type: none;
  margin: 0;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  overflow: hidden;
  z-index: 100000;
  margin-top: 20px;
}
.topBar ul{
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.topBar li{
  display: inline-block;
  width: 15%;
  padding-top: 6px;
  padding-bottom: 6px;
}
.topBar .iconDiv li{
  width: 80%;
  margin-right: 20%;

}
.topBar .iconDiv a{
  font-size: 1.5vw;

}
.topBar a:hover{
  background-color: #ffffff;
  border-radius: 21px;
  transition: background-color 1s;
}
.topBar .iconDiv a:hover{
  background-color: #ffffff;
}
.active{
  background-color: #ffffff;
  border-radius: 21px;
  transition: background-color 1s;
}
.topBar a{
  display: block;
  text-decoration: none;
  padding: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: black;
  outline: 1px solid transparent;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  transition: all .7s ease;  
}

.topBar .fillerDiv{
  display: inline-block;
  flex-grow: 1;
  min-height: 10px;
}
.topBar .iconDiv{
  display: block;
  width: 5%;
  min-height: 10px;
}
.topBar .content{
  background-color: #e9e9e9;
  opacity: .9;
  display: block;
  border-radius: 27px;
  width: 600px;
  height: 54px;
}
@media (min-width: 200px) {
  .topBar a{
    font-size: 12px;
    transition: all .7s ease;  
    padding-left: 5px; padding-right: 5px;
  }
  .topBar li{
    display: inline-block;
    width:fit-content;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: all .7s ease;  
  }
  .topBar .content{
    border-radius: 0px;
    width: 100%;
    height: 54px;
    transition: all .7s ease;  
  }
  .topBar .iconDiv {
    width: 0;
    transition: all .7s ease;  
  }
  .topBar{
    margin-top: 0;
    transition: all .7s ease;  
  }
}
@media (min-width: 620px) {
  .topBar a{
    font-size: 12px;
    transition: all .7s ease;  
  }
  .topBar li{
    display: inline-block;
    width: 18%;
    padding: 6px;
    transition: all .7s ease;  
  }
  .topBar .content{
    border-radius: 27px;
    width: 600px;
    height: 54px;
    transition: all .7s ease;  
  }
  .topBar .iconDiv {
    width: 5%;
    transition: all .7s ease;  
  }
  .topBar{
    margin-top: 20px;
    transition: all .7s ease;   
  }
  
}